// App.js
import React, { useEffect, useContext, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import GameComponent from './components/GameComponent';
import BlotPage from './components/BlotPage';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import TransactionHistory from './components/TransactionHistory';
import Profile from './components/Profile';
import FriendsPage from './components/Friends';
import Tasks from './components/Tasks';
import More from './components/More';
import PlayedGameHistory from './components/PlayedGameHistory';
import AffiliateProgram from './components/AffiliateProgram';
import ScoreHistory from './components/ScoreHistory'; 
import { AuthContext } from './context/AuthContext';
import { apiRequest } from './api';
import './App.css'; 
import API_BASE_URL from './apiConfig';
import LoadingScreen from './components/LoadingScreen';


const App = () => {
    const { user, balance, setBalance } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTransition, setPageTransition] = useState(false);
    const [isGeneratingLink, setIsGeneratingLink] = useState(false); 
    const [inviteLink, setInviteLink] = useState('');
    const [showCopyPopup, setShowCopyPopup] = useState(false);
    const [isGameLoading, setIsGameLoading] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const scrollableContent = document.getElementById('scrollableContent');

    useEffect(() => {
       
        setIsFooterVisible(true);
   
        if (scrollableContent) {
            scrollableContent.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

     
        
    }, [location.pathname]);
    

    useEffect(() => {

        const backButtonRoutes = [
            '/deposit',
            '/withdraw',
            '/transaction-history',
            '/played-games-history',
            '/affiliate-program',
            '/profile'
        ];

        if (window.Telegram?.WebApp) {

             if (backButtonRoutes.includes(location.pathname)) {
                window.Telegram.WebApp.BackButton.show();
                window.Telegram.WebApp.BackButton.onClick(() => {
                    navigate(-1); 
                });
            } else {
                window.Telegram.WebApp.BackButton.hide();
                window.Telegram.WebApp.onEvent('mainButtonClicked', () => {
                    window.Telegram.WebApp.close();
                });
            }
        }
        // Clean up event listeners
        return () => {
            window.Telegram.WebApp.BackButton.offClick();
        };
    }, [location, navigate]);


      
    useEffect(() => {
        if (user && location.pathname === '/login') {
            navigate('/game', { replace: true });
        }
    }, [user, location, navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referrerId = params.get('referrerId');


        if (referrerId) {
            localStorage.setItem('referrerId', referrerId);
        }
    }, [location]);

    useEffect(() => {
        if (!user?.inviteLink && !inviteLink) {

            handleGetInviteLink();
        }
    }, [user]);

    useEffect(() => {
        if (location.pathname === '/game') {
            window.history.pushState(null, document.title, location.pathname);

            const handlePopState = () => {
                window.history.pushState(null, document.title, location.pathname);
                navigate('/game', { replace: true });
            };

            window.addEventListener('popstate', handlePopState);

            return () => {
                window.removeEventListener('popstate', handlePopState);
            };
        }
    }, [location.pathname, navigate]);

    const handleNavigation = (path) => {
        setPageTransition(true); 
        setTimeout(() => {
            navigate(path); 
            setPageTransition(false);
        }, 200); 
    };

    const handleGetInviteLink = async () => {
        setIsGeneratingLink(true); 
        try {
            const response = await apiRequest(`${API_BASE_URL}/api/invite-link`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }); 
            const data = await response.json();
            if (response.ok) {
                setInviteLink(data.inviteLink);
            } else {
                console.error('Failed to generate invite link:', data.message);
            }
        } catch (error) {
            console.error('Error generating invite link:', error);
        } finally {
            setIsGeneratingLink(false); 
        }
    };

    const handleShareLink = async () => {

        const existingInviteLink = user?.inviteLink;
    

        const linkToShare = existingInviteLink || inviteLink || await handleGetInviteLink();
    

        const telegramShareLink = `https://t.me/share/url?url=${encodeURIComponent(linkToShare)}&text=${encodeURIComponent("💰 Join me and earn rewards! Let's play-to-earn together!")}`;
    

        window.open(telegramShareLink, '_blank');
    };

    const handleCopyLink = async () => {

        if (!inviteLink) {
            await handleGetInviteLink(); 
        }

        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(inviteLink);
            } else {
                const tempInput = document.createElement('input');
                tempInput.value = inviteLink;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand('copy');
                document.body.removeChild(tempInput);
            }

            setShowCopyPopup(true);
        } catch (err) {
            console.error('Failed to copy the link:', err);
        }
    };

    return (
        <div className="appPage">
            {isGameLoading && <LoadingScreen />}
            
            <div className={`page-container ${pageTransition ? 'page-exit' : ''}`}>
                <Routes>
                    <Route path="/register" element={<RegistrationForm />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/game" 
                        element={
                            user ? (
                                <GameComponent 
                                    balance={balance} 
                                    setBalance={setBalance} 
                                    setIsGameLoading={setIsGameLoading} 
                                    setFooterVisible={setIsFooterVisible}
                                />
                            ) : (
                                <LoginForm />
                            )
                        }
                    />
                    <Route path="/blot" element={user ? <BlotPage setBalance={setBalance} /> : <LoginForm />} />
                    <Route path="/deposit" 
                        element={
                            user ? 
                            <Deposit setFooterVisible={setIsFooterVisible} /> : 
                            <LoginForm />
                        } 
                    />
                    <Route path="/withdraw" element={user ? <Withdraw balance={balance} setBalance={setBalance} /> : <LoginForm />} />
                    <Route path="/transaction-history" element={user ? <TransactionHistory /> : <LoginForm />} />
                    <Route path="/played-games-history" element={user ? <PlayedGameHistory /> : <LoginForm />} />
                    <Route path="/affiliate-program" element={user ? <AffiliateProgram /> : <LoginForm />} />
                    <Route path="/profile" element={user ? <Profile /> : <LoginForm />} />
                    <Route path="/friends" element={user ? <FriendsPage balance={balance} setBalance={setBalance} /> : <LoginForm />} />
                    <Route path="/tasks" element={user ? <Tasks balance={balance} setBalance={setBalance} /> : <LoginForm />} />
                    <Route path="/more" element={user ? <More /> : <LoginForm />} />
                    <Route path="/score-history" element={user ? <ScoreHistory /> : <LoginForm />} />
                    <Route path="/" element={user ? <GameComponent balance={balance} setBalance={setBalance} /> : <LoginForm />} />
                </Routes>
            </div>

            {user && location.pathname === '/friends' && showCopyPopup && (
                <div className="copy-popup-overlay" onClick={() => setShowCopyPopup(false)}>
                    <div className="copy-popup" onClick={(e) => e.stopPropagation()}>
                        <div className="copy-popup-header">
                            <h3 className="copy-popup-title">Link Copied</h3> 
                            <button className="copy-popup-close-btn" onClick={() => setShowCopyPopup(false)}>&times;</button>
                        </div>
                        <p className="manual-copy-link">{inviteLink}</p>
                    </div>
                </div>
            )}

            {user && location.pathname === '/friends' && (
                <div className="fixed-buttons-container">
                    <button className="invite-button animated-pulse" onClick={handleShareLink}>
                        Invite a Friend
                    </button>
                    <button className="copy-link-button" onClick={handleCopyLink}>
                        <img src="/icons/copy-icon.svg" alt="Copy Icon" />
                    </button>
                </div>
            )}

            {user && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password/:token' && location.pathname !== '/blot' && !isGameLoading && (
                <footer className={`footer ${!isFooterVisible ? 'hidden-footer' : ''}`}>
                    <div className={`footer-item ${location.pathname === '/game' ? 'active' : ''}`} onClick={() => handleNavigation('/game')}>
                        <img src="/home/home.svg" alt="Game" className="footer-icon" />
                        <span>Games</span>
                    </div>
                    <div className={`footer-item ${location.pathname === '/friends' ? 'active' : ''}`} onClick={() => handleNavigation('/friends')}>
                        <img src="/home/friends.svg" alt="Friends" className="footer-icon" />
                        <span>Friends</span>
                    </div>
                    <div className={`footer-item ${location.pathname === '/tasks' ? 'active' : ''}`} onClick={() => handleNavigation('/tasks')}>
                        <img src="/home/tasks.svg" alt="Tasks" className="footer-icon" />
                        <span>Tasks</span>
                    </div>
                    <div className={`footer-item ${location.pathname === '/more' ? 'active' : ''}`} onClick={() => handleNavigation('/more')}>
                        <img src="/home/more.svg" alt="More" className="footer-icon" />
                        <span>More</span>
                    </div>
                </footer>
            )}
        </div>
    );
};

export default App;

